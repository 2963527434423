/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
// src/UserChat.js
import React, { useState, useEffect , useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { IoChatbubbles } from "react-icons/io5";
import { MdAttachFile , MdCancel } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";
import "./SupportPanel.css"
import Success from "./Success"
import {FaReply } from "react-icons/fa";
import { FaArrowTurnUp } from "react-icons/fa6";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import userImage from "../photoes/user.png"
import SupportImage from "../photoes/support.png"
const socket = io('https://chat.fenefx.net',{
  autoConnect:false,
  path: '/backend/socket.io'
});
//, { path: '/backend/socket.io' }

function UserChat({load}) {
  const [token, setToken] = useState(Cookies.get("ChatToken"));
  useEffect(()=>{
   if(token){
    socket.connect()

   }else{
    console.log("socket disconnected");

    socket.disconnect()
   }
  },[token])
    const [reply, setReply] = useState([]);
    
    const [chatId, setChatId] = useState(undefined);
    const [online, setOnline] = useState(false);
    const [message, setMessage] = useState('');
    const [modal, setModal] = useState(false);
    const [messages, setMessages] = useState([]);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState("");
    const [userId, setUserId] = useState(null);
    const [yekbar, setYekbar] = useState(0);
    const [showChat , setShowChat] = useState(false)
    const [file,setFile]= useState([])
    const refImage = useRef(null)
    const messageEndRef = useRef(null)
    const [isTyping, setIsTyping] = useState(false); // Track typing state
  const typingTimerRef = useRef(null); // Ref to hold the timeout

  const handleInputChange = (e) => {
    const message = e.target.value; // Get the current message value

    if (chatId) {
      // Clear the previous typing timer to reset the 1-second countdown
      if (typingTimerRef.current) {
        clearTimeout(typingTimerRef.current);
      }

      // Emit the 'typing' event with the current message every time the user types
      socket.emit(
        "typing",
        {chatId,
        userId:userId!==null?userId:Cookies.get("userId"),
        message}
      );

      // If the user is not already marked as typing, set it as true
      if (!isTyping) {
        setIsTyping(true); // Mark the user as typing
      }

      // Set a timeout to emit stopTyping after 1 second of inactivity
      typingTimerRef.current = setTimeout(() => {

        socket.emit(
          "stopTyping",
            {
            chatId,
            userId:userId!==null?userId:Cookies.get("userId")
            }
        );
        setIsTyping(false); // Reset the typing state after stopTyping is emitted
      }, 1000); // 1 second of inactivity
    }

    // Update the message state (if necessary)
    setMessage(message);
  };
    
    useEffect(()=>{
        if (messageEndRef.current) {
            messageEndRef.current.scroll({
              top: messageEndRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
     },[messages])
    useEffect(()=>{
          if(Cookies.get("ChatToken") ===undefined){
            Cookies.remove("userId")
          }
          window.addEventListener('message',(event)=>{
              if (event.data === 'openChat') {
                setOnline(true) 
                if(Cookies.get("ChatToken") ){
                  socket.emit("userConnected",userId!==null?userId:Cookies.get("userId")) 
                  load(true)
            axios.get('https://chat.fenefx.net/backend/api/userLatestChat',{
            params: {
                userId: Cookies.get("userId")?Cookies.get("userId"): userId  
            },
                headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}`}
            }) 
            .then(response => {
                load(false)
                setMessages(response.data.chat.messages);  
                setChatId(response.data.chat.chatId);
                setSelectedChat(response.data.chat);
                setShowChat(false)
                socket.emit('readMessage', response.data.chat.chatId  )
                socket.emit('markMessagesSeen', {chatId:response.data.chat.chatId ,
                      userId : Cookies.get("userId")?Cookies.get("userId"): userId}
                     )
                socket.emit('getOpenChats')
                
            })
            .catch(err => {
                console.error( err);
                load(false)
            }); 
                }
                
              } else if (event.data === 'closeChat') {
                setOnline(false)
                socket.emit("userDisconnected",userId!==null?userId:Cookies.get("userId"))
                socket.emit("getOpenChats")
              }
          });
            return () => {
                window.removeEventListener('message', (event)=>{
                    if (event.data === 'openChat') {
                        setOnline(true); 
                      } else if (event.data === 'closeChat') {
                        setOnline(false); 
                      }
                  });
              };
        },[])
    useEffect(() => {
    if (messageEndRef.current) {
            messageEndRef.current.scroll({
              top: messageEndRef.current.scrollHeight,
              behavior: "smooth",
            });
          }
        if (chatId && online) {
          let chatToken = Cookies.get("ChatToken")
            socket.emit('joinChat', {chatId,token:chatToken});
            socket.on('chatHistory', (chatMessages) => {
              if(chatMessages.find(e=>e.chat_id === chatId)) {
                  setMessages(chatMessages);
              }
          });
            socket.on('newMessage', (newMessage) => {
                
                if(showChat){
                  load(true)
                  axios.get('https://chat.fenefx.net/backend/api/userChat',{
                  params: {
                      userId: Cookies.get("userId") ? Cookies.get("userId"): userId
                      },
                  headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}` 
                  } 
                  }) 
                  .then(response => {
                       
                       setChats(response.data.chats)
                       load(false)
                   })
                   .catch(err => {
                      console.error( err);
                      load(false)
                   });
                }
                if(newMessage.chat_id == chatId){
                setMessages(prevMessages => [...prevMessages, newMessage]);
                socket.emit('readMessage', chatId  )
                socket.emit('markMessagesSeen', {chatId ,
                 userId: Cookies.get("userId")?Cookies.get("userId"): userId}
                     )
                socket.emit('getOpenChats')

                }
                
            });
            
            return () => {
                socket.off('newMessage');
            };
        }
    }, [chatId , online , showChat]);
    const divRefs = useRef({});
   const scrollToDiv = (key) => {
    const targetDiv = divRefs.current[key];
 
    if (targetDiv) {
      // Use scrollIntoView with 'nearest' to avoid scrolling the entire page or parent
      targetDiv.scrollIntoView({
        behavior: "smooth",
        block: "nearest", // Only scroll if the div is not fully visible
        inline: "nearest",
      });
    }
  };
  const convertTextToLinks = (text , c) => {
    // Regular expression to detect URLs (http/https/ftp)
    const urlRegex = /\b(https?:\/\/[^\s]+(?:\/[^\s]*)?)/gi;
    let color = c?"darkblue":"#00fe40"
    return text.split(urlRegex).map((part, index) => {
      // If this part is a URL, render it as an anchor tag
      if (urlRegex.test(part)) {
        return (
          <a style={{
            display:"inline-block",
            direction:"ltr",
            color: color,
            textAlign:"left",
            width:"90%"
          }} key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      // If it's not a URL, render the text as-is
      return part;
    });
  };
    const sendMessage = () => {
        const senderType = 'user';
        if (message.trim() === '') return; 
            if(chatId){
              load(false)
            if(reply.length!==0){
                socket.emit('sendMessage', chatId?chatId:selectedChat.chatId, message,
                    Cookies.get("userId") ? Cookies.get("userId"): userId, senderType , "message",reply[0].id);
                    setReply([])
              } else{
            
                socket.emit('sendMessage', chatId?chatId:selectedChat.chatId, message,
                     Cookies.get("userId") ? Cookies.get("userId"): userId, senderType , "message");
              } 
            socket.emit('sendChat', chatId?chatId:selectedChat.chatId, message);
            setMessage('');
            }else{
              function generateUniqueCode(length) {
                const timestamp = Date.now(); 
                let code = timestamp.toString().slice(-length); 
                while (code.length < length) {
                  code = '1' + code; 
                }
                return code;
            }
            
            load(true)
            axios.post('https://crm.fenefx.net/api/v1/chat/user/none',{
               user_id: generateUniqueCode(11)
              }) 
              .then(respon => {
                
                axios.post('https://chat.fenefx.net/backend/api/createChat',
                  {userId:respon.user_id},{
                    headers: { "Authorization": `bearer ${respon.data.token}`} 
                  })
                  .then(respons => {
                     
                      Cookies.set("ChatToken",respon.data.token ,{ expires: 30 })
                      setToken(respon.data.token)
                      setUserId(respons.data.userId)
                      setChatId(respons.data.id)
                      Cookies.set("userId",respons.data.userId ,{ expires: 30 })
                      
                      axios.get('https://chat.fenefx.net/backend/api/userLatestChat',{
                          params: {
                              userId: respons.data.userId  
                          },
                          headers: { "Authorization": `bearer ${respon.data.token}`}
                          }) 
                          .then(response => {
                              setMessage("")
                              socket.emit("userConnected",{token: respon.data.token})
                              socket.emit('joinChat', {chatId:respons.data.id,token:respon.data.token});
                              setMessages(response.data.chat.messages);  
                              setChatId(response.data.chat.chatId);
                              setSelectedChat(response.data.chat);
                              socket.emit('sendMessage', 
                                respons.data.id, 
                                message, 
                                respons.data.userId, 
                                "user","message"
                              );
                              socket.emit('sendChat', respons.data.id, message);
                            
                              load(false)
                          })
                          .catch(err => {
                              console.error( err);
                              load(false)
                          });
                      
                  })
                  .catch(err => {
                      console.error( err);
                      load(false)
                  });
              })
              .catch(err => {
                  console.error( err);
                  load(false)
              });
            
            }
            
        
    };
   
      
      
  const emailShow = ()=>{
    if(yekbar === 0){
     return <div className='emCon'>
       <div className='emailGiver'>
         <input value={email} onChange={(e)=>{
             setEmail(e.target.value)
           }} type='email' placeholder='ایمیل خود را وارد نمایید' />
          <input value={name} onChange={(e)=>{
             setName(e.target.value)
           }}  type='text' placeholder='نام کامل ( اختیاری )' />
          <button disabled={email === "" ? true:false} onClick={()=>{
            load(true)
            axios.post('https://crm.fenefx.net/api/v1/chat/user' , {
               email : email , name : name
            }) 
               .then(response => {
               
                Cookies.set("ChatToken",response.data.token)
                setToken(response.data.token)
                if(response.data.user){
                    axios.post('https://chat.fenefx.net/backend/api/createChat',
                        {userId:response.data.user.id},
                        { headers: { "Authorization": `bearer ${response.data.token}`} }
                      ) 
                        .then(res => {
                           
                            setUserId(res.data.userId)
                            Cookies.set("userId",res.data.userId ,{ expires: 30 }) 
                            axios.get('https://chat.fenefx.net/backend/api/userLatestChat',{
                                params: {
                                    userId: res.data.userId  
                                },
                                headers: { "Authorization": `bearer ${response.data.token}` 
                                } 
                               }) 
                                .then(r => {
                                    setMessages(r.data.chat.messages);  
                                    setChatId(r.data.chat.chatId);
                                    setSelectedChat(r.data.chat)
                                    setShowChat(false)
                                 
                                    load(false)
                                })
                                .catch(err => {
                                    console.error( err);
                                    load(false)
                    
                                });
                            
                            
                        })
                        .catch(err => {
                            console.error( err);
                            load(false)
                        });
                    
                }else{
                    NotificationManager.success("کد تایید ارسال شد")
                    setYekbar(1)
                    load(false)
                }
                  
               })
               .catch(err => {
                   console.error( err);
                   load(false)
               });
          }} className='btn submit'> ثبت اطلاعات </button>
      </div>
   </div>
    }else if(yekbar === 1){
      return <div className='emCon'>
      <div className='emailGiver'>
        <input value={code} onChange={(e)=>{
            setCode(e.target.value)
        }} type='code' placeholder='کد تایید ایمیل'/>
        <div style={{
        textAlign:"center", 
        padding:"5px", 
        background:"#00000024",
        borderRadius:"5px"
        }} onClick={()=>{
            setEmail("")
            setYekbar(0)
        }}> تغییر ایمیل </div>
        <button disabled={code === "" ? true:false} onClick={()=>{
            load(true)
             axios.post('https://crm.fenefx.net/api/v1/chat/user/confirm' , {
                email:email , code:code 
             }) 
                .then(res => {
                  
                    socket.emit("userConnected",{token: res.data.token})
                    Cookies.set("ChatToken",res.data.token)
                    setToken(res.data.token)
                    axios.get('https://chat.fenefx.net/backend/api/userChat',{
                        params: {
                            userId: res.data.user_id
                        },
                        headers: { "Authorization": `bearer ${res.data.token}`}
                       }) 
                        .then(response => {
                            
                 
                            setChats(response.data.chats)
                            Cookies.set("userId",res.data.user_id)
                            setUserId(res.data.user_id)
                            
                            if(response.data.chats.length===0){
                                load(true)
                            axios.post('https://chat.fenefx.net/backend/api/createChat',
                            {userId:userId!==null?userId:Cookies.get("userId")},
                             {
                              headers: { "Authorization": `bearer ${res.data.token}`}
                             }
                             ) // Adjust the endpoint as needed
                            .then(respo => {
                          
                                setUserId(respo.data.userId)
                                Cookies.set("userId",respo.data.userId ,{ expires: 30 }) 
                                axios.get('https://chat.fenefx.net/backend/api/userLatestChat',{
                                    params: {
                                        userId: respo.data.userId  
                                    },
                                    headers: { "Authorization": `bearer ${res.data.token}`}
                                }) 
                                    .then(r => {
                                        setMessages(r.data.chat.messages);  
                                        setChatId(r.data.chat.chatId);
                                        setSelectedChat(r.data.chat)
                                        setShowChat(false)
                                  
                                        load(false)
                                    })
                                    .catch(err => {
                                        console.error( err);
                                        load(false)
                                    });
                                
                                
                            })
                            .catch(err => {
                                console.error( err);
                                load(false)
                            });
                            }else{
                              setSelectedChat(null)
                              setShowChat(true)
                              load(false)
                            }
                            
                        })
                        .catch(err => {
                            console.error( err);
                            load(false)
                         });
                })
                .catch(err => {
                    NotificationManager.error("کد تایید صحیح نمی باشد")
                    console.error( err);
                    load(false)
    
                });
        }} className='btn submit'> تایید کد </button>
       </div>
    </div>
    }
  }
  
  const truncateText = (text, wordLimit) => {
    const imagePattern = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
    const zipPattern = /\.zip$/i;
    const urlWithoutQuery = text.split('?')[0];
    if(text){
      if(imagePattern.test(urlWithoutQuery)){
        return "تصویر"
      }else if(zipPattern.test(urlWithoutQuery)){
      return "فایل"
      }else{
        if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
       }else{
        return text;
       }
      } 
    }
  };
  const inModal = ()=>{
    if(file[1]==="image"){
        return <>
        <div> آیا از ارسال تصویر مورد نظر اطمینان دارید؟ </div>
         <div> برای ارسال تصویر گزینه ارسال را بزنید </div>
         <img style={{maxHeight:"200px", maxWidth:"150px" ,margin:"10px" }} src={URL.createObjectURL(file[0])} alt='sendPhoto'/>
         <div> 
            <button className='sendBtn' onClick={()=>{
                load(true)
                let data = {
                    file:file[0],
                    senderId:Cookies.get("userId")?Cookies.get("userId"): userId,
                    chatId:chatId,
                    type:"user"
                }
   
                axios.post('https://chat.fenefx.net/backend/api/upload',data,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                         "Authorization": `bearer ${Cookies.get("ChatToken")}`
                    }
                }) 
                    .then(response => {
             
                          socket.emit('sendMessage', chatId , response.data.fileUrl,
                          Cookies.get("userId")?Cookies.get("userId"): userId, "user","image");
                          socket.emit('sendChat', chatId, response.data.fileUrl);
                          setModal(false)
                          load(false)
                    })
                    .catch(err => {
                        console.error(err);
                        load(false)
        
                    });
            }} > ارسال </button>
            <button className='sendBtn' onClick={()=>{
                setModal(false)
                setFile([])
            }}> انصراف </button>
         </div>
        </>
    }else if(file[1]==="file"){
        return <>
        <div> آیا از ارسال فایل مورد نظر اطمینان دارید؟ </div>
        <div style={{direction:"rtl"}}> برای ارسال فایل {" ( "+file[2]+" ) "} گزینه ارسال را بزنید </div>
        <div> 
           <button onClick={()=>{
            load(true)
            let data = {
                file:file[0],
                senderId:Cookies.get("userId")?Cookies.get("userId"): userId,
                chatId:chatId,
                type:"user"
            }

            axios.post('https://chat.fenefx.net/backend/api/upload',data,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `bearer ${Cookies.get("ChatToken")}`
                }
            }) 
                .then(response => {
                   
                      socket.emit('sendMessage', chatId, response.data.fileUrl,
                      Cookies.get("userId")?Cookies.get("userId"): userId, "user","file");
                      socket.emit('sendChat', chatId, response.data.fileUrl);
                      setModal(false)
                      load(false)
                })
                .catch(err => {
                    console.error(err);
                    load(false)
                });
           }} className='sendBtn' > ارسال </button>
           <button className='sendBtn' onClick={()=>{
               setModal(false)
               setFile([])
           }}> انصراف </button>
        </div>
       </>
    }else if(file[1]==="close"){
         return <>
         <div style={{marginTop:"10px"}}> 
            {file[2]?<>
             <div style={{margin:"10px"}}> برای خروج از کاربری فعلی گزینه خروج را بزنید
                ، برای اتمام چت گزینه اتمام چت را بزنید </div>
            <button className='sendBtn' onClick={()=>{
                load(true)
            let data = {
                userId:Cookies.get("userId")?Cookies.get("userId"): userId,
                chatId:chatId
            }
              axios.post('https://chat.fenefx.net/backend/api/closeChat',data,{
                
                headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}`}
              }) 
                .then(response => {
                    
                      setChats(response.data.userChats)
                      setChatId(undefined)
                      setShowChat(true)
                      setModal(false)
                      socket.emit("getOpenChats")
                      load(false)
                })
                .catch(err => {
                    console.error(err);
                    load(false)
                });
                setModal(false)
                setFile([])
            }}> اتمام چت </button>
            <button className='sendBtn' onClick={()=>{
                Cookies.remove("userId")
                Cookies.remove("ChatToken")
                setToken(undefined)
                setChatId(undefined)
                setUserId(null)
                setModal(false)
                setSelectedChat("")
                setFile([])
            }}> خروج </button></>:
              <>
                <div style={{margin:"10px"}}> برای خروج از کاربری فعلی گزینه خروج را بزنید </div>
                <button style={{width:"100%"}} className='sendBtn' onClick={()=>{
                Cookies.remove("userId")
                Cookies.remove("ChatToken")
                setToken(undefined)
                setChatId(undefined)
                setUserId(null)
                setModal(false)
                setSelectedChat("")
                setFile([])
                setShowChat(false)
            }}> خروج </button>
              </>
            }
         </div>
        </>
    }else{
        return <>
        <div> فرمت فایل ارسالی صحیح نمی باشد </div>
        
        <div> 
           
           <button className='sendBtn' onClick={()=>{
               setModal(false)
               setFile([])
           }}> فهمیدم </button>
        </div>
       </>
    }
  }
   const lastMessage = (d)=>{
    if(d.messages.length===0){
        return "پیامی وجود ندارد"
    }else{
    if(d.messages[d.messages.length-1].message_type === "image"){
       return "تصویر"
    }else if(d.messages[d.messages.length-1].message_type === "file"){
        return "فایل"
    }else{
        return truncateText(d.messages[d.messages.length-1].message ,20)
    }
    }
   }
   const handleKeyDown = (event) => {
    
    if (event.key === 'Enter') {
        if (event.shiftKey) {
            // Shift + Enter pressed, insert a new line
            event.preventDefault();
            setMessage(message + '\n');
        } else {
            // Enter pressed, trigger the button click
            event.preventDefault();
            sendMessage();
        }
    }
};
const replyHandle = (msg)=>{
    setReply([msg])
  }
    return (
        <div>
            <body>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/css/bootstrap.min.css" rel="stylesheet"></link>
    <div >
        <div className="chat-header clearfix">
            <div>
                <div>
                    <input disabled={selectedChat!==null&&selectedChat.status!=="closed"?false:true}
                     accept=".zip,image/*" type='file'ref={refImage} hidden onChange={(e)=>{
                        const file = e.target.files[0];
                        if (file) {
                          const fileType = file.type;
                          const fileName = file.name;
            
                          
                          if (fileType === 'application/zip' || fileName.endsWith('.zip')) {
                            setFile([e.target.files[0],"file" , fileName])
                             setModal(true)

                          } else if (fileType.startsWith('image/')) {
                            setFile([e.target.files[0],"image"])
                            setModal(true)
                            
                          } else {
                            setFile([e.target.files[0],"nothing"])
                            setModal(true)
                            
                            
                          }
                        }
                        
                    }}/>
                    
                    {showChat?<div >
                        <div style={{display:"flex", 
                        justifyContent:"center", 
                        width:"100%",
                        position:"absolute",
                        top:"0px",
                        right:"0px",
                        background:"linear-gradient(77deg, rgba(8,107,0,1) 0%, rgba(4,24,0,1) 100%)",
                        padding:"7px",
                        height:"50px"  
                        }}>
                        <div style={{color:"#eeee",textAlign:"center",padding:"5px",fontSize:"13px"}}>
                            چت های قبلی
                        </div>
                      </div>
                    <div id="plist"  className="people-list">
                      <ul style={{
                        padding:"10px",
                        marginTop:"50px",
                        maxHeight:"83vh",
                        overflowY:"auto",
                        
                      }} className="list-unstyled chat-list ">
                        {chats.map(d=>{
                            return <li 
                            onClick={() =>{
                                
                                if(online){
                                    socket.emit('readMessage', d.chatId  )
                                    socket.emit('markMessagesSeen', {chatId:d.chatId ,
                                          userId:  Cookies.get("userId")?Cookies.get("userId"): userId}
                                         )
                                    socket.emit('getOpenChats')
                                  
                                }
                                setChatId(d.chatId)
                                setMessages(d.messages)
                                setSelectedChat(d)
                                setShowChat(false)
                            }} key={d.chatId} className="clearfix eachChat">
                            <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="avatar"/>
                            <div style={{display:"flex",
                                justifyContent:"space-between",
                                width:"100%",
                                }}>
                            <div className="about">
                                <div className="name">{lastMessage(d)}</div>
                                <div className="status"> {d.created_at} </div>
                            </div>
                            <div style={{
                            textAlign:"center",
                            minWidth:"100px",
                            alignContent:"center"
                            }}> {d.status==="closed"?
                               <div className='red'> بسته شده </div>:
                               <div className='green'> باز </div>
                                 } 
                            </div>
                            </div>
                         </li>
                        })}
                      </ul>
                      </div>
                      <div style={{display:"flex", 
                        justifyContent:"center", 
                        width:"100%",
                        position:"absolute",
                        bottom:"0px",
                        right:"0px",
                        background:"linear-gradient(77deg, rgba(8,107,0,1) 0%, rgba(4,24,0,1) 100%)",
                        padding:"7px"     
                        }}>
                        <button style={{
                            fontSize:"12px",
                            background:"rgba(255, 255, 255, 0.35)",
                            color:"#eeee"
                        }}  className='sendBtn' onClick={()=>{
                            setFile(["","close",false])
                            setModal(true)
                          
                        }}> 
                          خروج 
                        </button>
                        <button style={{
                            fontSize:"12px",
                            background:"rgba(255, 255, 255, 0.35)",
                            color:"#eeee"
                        }} className='sendBtn' onClick={()=>{
                            load(true)
                            axios.post('https://chat.fenefx.net/backend/api/createChat',
                            {userId:userId!==null?userId:Cookies.get("userId")},{
                            headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}`}
                            }) // Adjust the endpoint as needed
                            .then(response => {
                     
                                setUserId(response.data.userId)
                                Cookies.set("userId",response.data.userId ,{ expires: 30 }) 
                                axios.get('https://chat.fenefx.net/backend/api/userLatestChat',{
                                    params: {
                                        userId: response.data.userId  
                                    },
                                    headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}`}
                                }) 
                                    .then(res => {
                                        setMessages(res.data.chat.messages);  
                                        setChatId(res.data.chat.chatId);
                                        setSelectedChat(res.data.chat)
                                        setShowChat(false)
                                  
                                        load(false)
                                    })
                                    .catch(err => {
                                        console.error( err);
                                        load(false)
                                    });
                                
                                
                            })
                            .catch(err => {
                                console.error( err);
                                load(false)
                            });
                        }}> 
                          چت جدید 
                        </button>
                      </div>
                    </div>
                    :
                      <div className="chat">
                        <div className="chat-header clearfix">
                            <div className="row">
                                <div style={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }} className="col-lg-6">
                                    <a  href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                        <img style={{marginTop:"7px"}} src={SupportImage} alt="avatar"/>
                                    </a>
                                    <div className="chat-about">
                                        <h6 style={{direction:"rtl",margin:"3px 0px 0px 0px"}}>  پشتیبانی FeneFx</h6>
                                        <small>{messages.length!==0? messages[0].created_at:null}</small>
                                    </div>
                                    <div style={{
                                        display:"flex",
                                        justifyContent:"right",
                                        width:"49%",
                                        paddingRight:"0px",
                                        marginTop:"7px"
                                    }}  className="col-lg-6 hidden-sm text-right">
                                    {selectedChat!==null&&selectedChat.status!=="closed"?<>{userId!==null||Cookies.get("userId")?<a style={{
                                     padding:"10px" 
                                     }}  href="javascript:void(0);" className="btn-outline-secondary">
                                    <IoIosCloseCircle onClick={()=>{
                                        setFile(["","close",true])
                                        setModal(true)

                                    }} style={{fontSize:"30px",verticalAlign:"middle" }} />
                                    </a>:null}
                                    {userId!==null||Cookies.get("userId")?<a onClick={()=>{
                                         refImage.current.click()
                                      }}  style={{
                                     padding:"10px" 
                                     }} href="javascript:void(0);" className="btn-outline-primary">
                                      <MdAttachFile style={{fontSize:"25px",verticalAlign:"middle" }} />
                                    </a>:null}</>:null}
                                    
                             {userId!==null||Cookies.get("userId")?<a onClick={()=>{
                                load(false)
                                axios.get('https://chat.fenefx.net/backend/api/userChat',{
                                params: {
                                    userId: Cookies.get("userId") ? Cookies.get("userId"): userId
                                },
                                  headers: { "Authorization": `bearer ${Cookies.get("ChatToken")}`}
                               }) 
                                .then(response => {
                                    
                                    setChats(response.data.chats)
                                    setSelectedChat(null)
                                    
                                    setShowChat(true)
                                    load(false)
                                })
                                .catch(err => {
                                    console.error( err);
                                    load(false)
                                 });
                               }} href="javascript:void(0);" style={{
                                padding:"10px" 
                               }} className=" btn-outline-info">
                                <IoChatbubbles style={{fontSize:"25px",verticalAlign:"middle" }} />
                               </a>:null}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div ref={messageEndRef} className="chat-history">
                            {Cookies.get("userId") || userId !== null?<ul className="m-b-0">
                            {messages.map(msg =>{
                                if(msg.type === "user"){
                                    if(msg.message_type ==="message"){
                                      return <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                      <div  className="message-data text-right">
                                      
                                        <span  className="message-data-time">{msg.created_at} </span>
                                        <img style={{marginLeft:"3px"}} src={userImage} alt="avatar"/>
                                       </div>
                                      <div style={{display:"flex",gap:"5px",justifyContent:"end",
                                            alignItems:"center"}}>
                                      <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           transform:"scaleX(-1)",
                                           cursor:"pointer"
                                        }}
                                        />
                                        <div className="message other-message float-right">
                                         {convertTextToLinks(msg.message,true)} </div>
                                      </div>
                                      {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"end"}}>
                                        
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee"}}/>
                                      </div>
                                        }
                                     </li>  
                                    }else if(msg.message_type ==="file"){
                                         return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                        <div  className="message-data text-right">
                                        
                                          <span  className="message-data-time">{msg.created_at} </span>
                                          <img style={{marginLeft:"3px"}} src={userImage} alt="avatar"/>
                                         </div>
                                         <div style={{display:"flex",gap:"5px",justifyContent:"end",
                                            alignItems:"center"}}>
                                      <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           transform:"scaleX(-1)",
                                           cursor:"pointer"
                                        }}
                                        />
                                        <div className="message other-message float-right"> 
                                          <a href={msg.message} target='_blank' rel="noreferrer" >
                                             برای دانلود فایل کلیک نمایید</a>
                                         </div>
                                        </div>
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"end"}}>
                                        
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee"}}/>
                                      </div>
                                        }
                                       </li> 
                                    }else if(msg.message_type ==="image"){
                                        return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                        <div  className="message-data text-right">
                                          <span  className="message-data-time">{msg.created_at} </span>
                                          <img style={{marginLeft:"3px"}} src={userImage} alt="avatar"/>
                                         </div>
                                         <div style={{display:"flex",gap:"5px",justifyContent:"end",
                                            alignItems:"center"}}>
                                      <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           transform:"scaleX(-1)",
                                           cursor:"pointer"
                                        }}
                                        />
                                        <div style={{textAlign:"center"}} className="message other-message float-right"> 
                                          <img onClick={()=>{
                                            window.open(msg.message,"_blank")
                                          }} src={msg.message} 
                                          style={{maxHeight:"200px",maxWidth:"200px"}}
                                          alt='sendedImage' /> 
                                         </div>
                                        </div>
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"end"}}>
                                        
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee"}}/>
                                      </div>
                                        }
                                       </li> 
                                    }
                                    
                                }else{
                                    if(msg.message_type ==="message"){
                                        return <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                        <div className="message-data ">
                                          <img style={{marginRight:"3px"}} src={SupportImage} alt="avatar"/>
                                          <span  className="message-data-time">{msg.created_at} </span>
                                         </div>
                                         <div style={{display:"flex",gap:"5px",
                                            justifyContent:"start",
                                            alignItems:"center"}}>
                                      
                                        <div className="message my-message"> 
                                          {convertTextToLinks(msg.message, false)} </div>
                                        <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           cursor:"pointer"
                                        }}
                                        />
                                        </div>
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"start"}}>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee",transform:"scaleX(-1)"}}/>
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        
                                      </div>
                                        }
                                       </li>  
                                      }else if(msg.message_type ==="file"){
                                           return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                          <div className="message-data ">
                                            <img style={{marginRight:"3px"}} src={SupportImage} alt="avatar"/>
                                            <span  className="message-data-time">{msg.created_at} </span>
                                           </div>
                                           <div style={{display:"flex",gap:"5px",justifyContent:"start",
                                            alignItems:"center"}}>
                                          <div className="message my-message "> 
                                            <a href={msg.message} target='_blank' rel="noreferrer" >
                                               برای دانلود فایل کلیک نمایید</a>
                                          </div>
                                          <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           cursor:"pointer"
                                        }}
                                        />
                                        </div>
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"start"}}>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee",transform:"scaleX(-1)"}}/>
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        
                                      </div>
                                        }
                                         </li> 
                                      }else if(msg.message_type ==="image"){
                                          return  <li ref={(el) => (divRefs.current[msg.id] = el)} key={msg.id} className="clearfix">
                                          <div className="message-data ">
                                            <img style={{marginRight:"3px"}} src={SupportImage} alt="avatar"/>
                                            <span  className="message-data-time">{msg.created_at} </span>
                                           </div>
                                           <div style={{display:"flex",gap:"5px",
                                            justifyContent:"start",
                                            alignItems:"center"}}>
                                          <div className="message my-message"> 
                                            <img src={msg.message} 
                                            style={{maxHeight:"200px",maxWidth:"200px"}}
                                            alt='sendedImage' />
                                          </div>
                                          <FaReply onClick={()=>{
                                           replyHandle(msg)
                                           }} style={{
                                           color:"#eeee",
                                           fontSize:"20px" ,
                                           verticalAlign:"end",
                                           textAlign:"right",
                                           cursor:"pointer"
                                        }}
                                        />
                                        </div>
                                        {msg.reply_to&&msg.replied_to_message&&msg.reply_to!==null&& 
                                        <div style={{ marginTop:"5px", display:"flex",
                                         gap:"5px",justifyContent:"start"}}>
                                        <FaArrowTurnUp style={{fontSize:"30px", color:"#eeee",transform:"scaleX(-1)"}}/>
                                        <div onClick={()=>{
                                          scrollToDiv(msg.reply_to)
                                        }} style={{
                                          padding:"10px",
                                          borderRadius:"10px",
                                          background:"#ffffffd1",
                                          color:"black",
                                          fontSize:"11px",
                                          textAlign:"right"
                                        }}> {truncateText(msg.replied_to_message.message,80)}</div>
                                        
                                      </div>
                                        }
                                         </li> 
                                      }
                                }
                              } 
                            )}
                               
                            </ul>:
                            emailShow()
                            }
                        </div>
                        <div className={reply[0]?"replyMessage show":"replyMessage h0px"} 
                                style={{
                                width:"100%",
                                background:"rgba(255, 255, 255, 0.81)",
                                color:"#5b5b5b",
                                direction:"rtl"}}>
                                   <div style={{
                                     borderBottom:"1px solid #5b5b5b"
                                    ,display:"flex"
                                    ,justifyContent:"space-between"
                                    ,marginBottom:"5px"
                                    }}>
                                    <div style={{
                                    paddingBottom:"5px",
                                    direction:"rtl",
                                    }}> پاسخ برای :</div>
                                    <MdCancel onClick={()=>{
                                       setReply([])
                                   }} style={{fontSize:"20px"}} />
                                   </div>
                                   <div>{reply[0]&& truncateText(reply[0].message,50)} </div>
                                </div>
                        {(selectedChat!==null&&selectedChat.status!=="closed")||selectedChat===""?<div className="chat-message clearfix">
                            
                            <form onSubmit={(e)=>{e.preventDefault()}}>
                            <div className="input-group mb-0">
                                <div className="input-group-prepend">
                                    <span onClick={sendMessage}  className="input-group-text"><i className="fa fa-send"></i></span>
                                </div>
                                <textarea onKeyDown={handleKeyDown} style={{direction:"rtl",textAlign:"right",fontSize:"12px"}} 
                                onChange={handleInputChange}
                                value={message} 
                                type="text" className="form-control" placeholder="پیام خود را وارد نمایید..."/>
                            </div>
                            </form>
                        </div>:
                          <div style={{display:"flex", 
                            justifyContent:"center", 
                            width:"100%",
                            position:"absolute",
                            bottom:"0px",
                            right:"0px",
                            background:"linear-gradient(77deg, rgba(8,107,0,1) 0%, rgba(4,24,0,1) 100%)",
                            padding:"7px"     
                            }}>
                            <button style={{
                                fontSize:"12px",
                                background:"rgba(255, 255, 255, 0.35)",
                                color:"#eeee"
                            }}  className='sendBtn' > 
                              چت بسته شده
                            </button>
                            
                          </div>
                        }
                    </div>}
                </div>
            </div>
        </div>
    </div>
    <script src="https://code.jquery.com/jquery-1.10.2.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/js/bootstrap.bundle.min.js"></script>
    <script type="text/javascript">

    </script>
    <Success name="chat" show={modal} closeModal={()=>{setModal(false)
        setFile("")
    }} >
        {inModal()}
    </Success>
    <NotificationContainer/>
    </body>
           
        </div>
    );
}

export default UserChat;
