
import React from 'react'
import Modal from 'react-modal';
import "./Success.css"
import { IoIosCloseCircleOutline } from "react-icons/io";
export default function Success(props) {
  return (
    
      <Modal
        isOpen={props.show}
        onRequestClose={props.closeModal}
        contentLabel={props.name}
        className="allModals"
        style={{
          content: {
            zIndex: 100
          },
        }}
      >
        
        <button className='modalClose' onClick={props.closeModal}>
        <IoIosCloseCircleOutline />
        </button>
        {props.children}
      </Modal>
  
  )
}
