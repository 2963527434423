import React, { useState } from 'react';
import UserChat from './component/UserChat';
import { ThreeCircles } from 'react-loader-spinner'

function App() {
  const [loading, setLoading] = useState(false);

  const load = (lion) => {
    setLoading(lion);
  };

  return (
    <div className="App">
      {loading&&<ThreeCircles
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="three-dots-loading"
        wrapperClass='loader'
      />}
      <UserChat load={load} />
    </div>
  );
}

export default App;
